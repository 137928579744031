import styled from "styled-components";
import { ClientPlayerSnapshot } from "../types/serverTypes";

type PlayerCardProps = {
  player: ClientPlayerSnapshot;
  onClick: () => void;
  focused: string[];
  isMe: boolean;
};

const PlayerCardWrapper = styled.div<{ $isAlive: boolean; $isMe: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.$isMe ? "lightblue" : "white")};
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  opacity: ${(props) => (props.$isAlive ? "1" : "0.5")};
  cursor: ${(props) => (props.$isAlive ? "pointer" : "auto")};
`;

export const PlayerCard = ({
  player,
  onClick,
  focused,
  isMe = false,
}: PlayerCardProps) => {
  return (
    <PlayerCardWrapper
      onClick={player.isAlive ? onClick : undefined}
      $isAlive={player.isAlive}
      $isMe={isMe}
    >
      <div className="w-3/10">
        <img
          src={`/sprites/sprite-${player.avatarId}.png`}
          alt={player.nickname}
          className="h-16 max-h-60 mb-2 object-cover"
        />
      </div>
      <div className="w-7/10 p-4">
        <h3 className="text-lg font-bold text-black">
          {isMe ? "You" : `Nickname: ${player.nickname}`}
        </h3>
        <p className="text-black">Role: {player.role || "-"}</p>
      </div>
      <div className="absolute bottom-[-8px] w-full h-8 ">
        {focused.map((imgSrc, index) => {
          return (
            <div
              className={`bg-white inline-block	ml-1 shadow-lg rounded-lg border-2 border-indigo-600 w-8 h-8 overflow-hidden`}
            >
              <img
                src={imgSrc}
                alt={player.nickname}
                className="h-8 max-h-60 mb-2 object-cover"
              />
            </div>
          );
        })}
      </div>
    </PlayerCardWrapper>
  );
};
