import "./App.css";
import Router from "./Router";

import { GlobalTimerComponent } from "./components/Timer";
import { GameClockProvider } from "./context/GameClockContext";
import { GameProvider } from "./context/GameContext";
import { SocketProvider } from "./context/SocketEventsContext";

function App() {
  return (
    <div className="App">
      <GameClockProvider>
        <GameProvider>
          <SocketProvider>
            {/* <Navbar /> */}
            <main style={{ minHeight: "100vh" }}>
              <GlobalTimerComponent />
              <Router />
            </main>
          </SocketProvider>
        </GameProvider>
      </GameClockProvider>
    </div>
  );
}

export default App;
