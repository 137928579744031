import { useGameContext } from "../../context/GameContext";
import { useSocketEvents } from "../../context/SocketEventsContext";
import { Action, DayAction, NightAction, Role } from "../../types/serverTypes";

import { useState } from "react";
import { PlayerCard } from "../../components/PlayerCard";

export const Game = () => {
  const { gameState } = useGameContext();
  const { performActionEvent } = useSocketEvents();

  // const [showDeathScreen, setShowDeathScreen] = useState(true);
  const [queuedTarget, setQueuedTarget] = useState<string | null>(null);
  const [queuedAction, setQueuedAction] = useState<Action | null>(null);

  const me = gameState.private.me;
  const role = me?.role as Role;
  const isNight = gameState.roundNumber % 2 === 1;

  const handlePerformAction = (action: Action, target: string) => {
    if (role && target) {
      performActionEvent({
        code: gameState.code,
        action,
        target,
      });
      setQueuedTarget(null);
    }
  };

  const handleConfirmation = (confirmed: boolean) => {
    if (!queuedAction || !queuedTarget) return;
    if (confirmed) {
      handlePerformAction(queuedAction, queuedTarget);
    }
    setQueuedAction(null);
  };

  // const clearDeathScreen = () => {
  //   setShowDeathScreen(false);
  // };

  const handleMakeAnAction = (action: Action, target: string) => {
    if (
      role === Role.VAMPIRE &&
      gameState.players.some(
        (player) => player.nickname === target && player.role === Role.VAMPIRE
      )
    ) {
      return;
    }

    if (action === NightAction.VAMPIRE_HOVER) {
      handlePerformAction(action, target);
    } else {
      setQueuedTarget(target);
      setQueuedAction(action);
    }
  };

  return (
    <>
      {/* {!gameState.me?.isAlive && showDeathScreen && ( */}
      <div className="min-h-screen flex items-center justify-center flex-col p-8 ">
        <h1 className="text-white uppercase text-xl mb-4 mt-0">
          {me?.acted && "Waiting for other players..."}
        </h1>
        <div className="bg-white bg-opacity-10 mx-auto w-full max-w-screen-xl shadow-md rounded-lg flex flex-col items-center">
          <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {gameState.players.map((player, index) => {
                let action: Action;
                if (isNight) {
                  if (role === Role.VAMPIRE) {
                    // if already hovering the target
                    if (
                      gameState.private.hovered?.find(
                        (item) =>
                          item.nickname === gameState.private.me?.nickname &&
                          item.target === player.nickname
                      )
                    ) {
                      action = NightAction.KILL;
                    } else {
                      action = NightAction.VAMPIRE_HOVER;
                    }
                  }
                  if (role === Role.SEER) {
                    action = NightAction.SEE;
                  }

                  if (role === Role.DOCTOR) {
                    action = NightAction.SAVE;
                  }

                  if (role === Role.VILLAGER) {
                    action = NightAction.KISS;
                  }
                } else {
                  action = DayAction.VOTE;
                }

                const target = player.nickname;

                const hovered = gameState.private.hovered?.filter(
                  (item) => item.target === player.nickname
                );

                const imgSrcs = hovered?.map((item) => {
                  const player = gameState.players.find(
                    (player) => player.nickname === item.nickname
                  );
                  return `/sprites/sprite-${player?.avatarId}.png`;
                });

                return (
                  <PlayerCard
                    key={index}
                    player={player}
                    isMe={me?.nickname === player.nickname}
                    onClick={() =>
                      me?.isAlive && handleMakeAnAction(action, target)
                    }
                    focused={imgSrcs || []}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {queuedAction && (
          <div className="confirmation-dialog fixed inset-0 flex items-center justify-center z-100">
            <div className="bg-white p-4 rounded shadow-lg z-100">
              <p className="mb-4 text-black">
                Are you sure you want to perform action "{queuedAction}" on{" "}
                {queuedTarget}
                {/* {isNight ? roleActionMap[role].toLocaleLowerCase() : "vote for"}{" "}
                {target}? */}
              </p>
              <div className="flex justify-end">
                <button
                  className="bg-green-500 text-white px-4 py-2 mr-2 rounded hover:bg-green-700"
                  onClick={() => handleConfirmation(true)}
                >
                  Yes
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                  onClick={() => handleConfirmation(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
