import React from "react";

interface SelectSpriteProps {
  onSelectSprite: (index: string) => void;
}

const SelectSprite: React.FC<SelectSpriteProps> = ({ onSelectSprite }) => {
  interface Image {
    url: string;
    pathToSound: HTMLAudioElement;
  }

  const sprite0Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-0-audio.mp3"
  );
  const sprite1Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-1-audio.mp3"
  );

  const sprite2Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-2-audio.mp3"
  );

  const sprite3Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-3-audio.mp3"
  );

  const sprite4Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-4-audio.mp3"
  );

  const sprite5Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-5-audio.mp3"
  );

  const sprite6Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-6-audio.mp3"
  );

  const sprite7Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-7-audio.mp3"
  );

  const sprite8Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-8-audio.mp3"
  );

  const sprite9Audio = new Audio(
    process.env.PUBLIC_URL + "/sprites/sprite-9-audio.mp3"
  );

  const images: Image[] = [
    {
      url: "/sprites/sprite-0.png",
      pathToSound: sprite0Audio,
    },
    {
      url: "/sprites/sprite-1.png",
      pathToSound: sprite1Audio,
    },
    {
      url: "/sprites/sprite-2.png",
      pathToSound: sprite2Audio,
    },
    {
      url: "/sprites/sprite-3.png",
      pathToSound: sprite3Audio,
    },
    {
      url: "/sprites/sprite-4.png",
      pathToSound: sprite4Audio,
    },
    {
      url: "/sprites/sprite-5.png",
      pathToSound: sprite5Audio,
    },
    {
      url: "/sprites/sprite-6.png",
      pathToSound: sprite6Audio,
    },
    {
      url: "/sprites/sprite-7.png",
      pathToSound: sprite7Audio,
    },
    {
      url: "/sprites/sprite-8.png",
      pathToSound: sprite8Audio,
    },
    {
      url: "/sprites/sprite-9.png",
      pathToSound: sprite9Audio,
    },

    // Add more images as needed
  ];

  // const handleButtonClick =
  //   (pathToSound: HTMLAudioElement) =>
  //   (e: React.MouseEvent<HTMLButtonElement>) => {
  //     pathToSound.play().catch(function (error: string) {
  //       console.log("Error playing click sound:", error);
  //     });
  //   };

  const handleButtonClick = (index: number) => {
    onSelectSprite(index.toString());
    images[index].pathToSound.play().catch(function (error: string) {
      console.log("Error playing click sound:", error);
    });
  };

  return (
    <div className="p-8 flex flex-col items-center">
      <p className="text-center pb-4">Choose a character</p>
      <div className="flex flex-wrap justify-center">
        {images.map((image: Image, index: number) => (
          <div
            className="w-1/3 lg:w-1/4 xl:w-1/5 flex items-center"
            key={index}
          >
            <button
              className="flex items-center mx-auto"
              onClick={() => handleButtonClick(index)}
            >
              <img
                className="h-14 md:h-20 mb-2"
                src={image.url}
                alt="A friendly villager"
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectSprite;
