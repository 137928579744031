import { ChangeEvent, useState } from "react";
import { ButtonBack, ButtonOrange, ButtonPurple } from "../components/Buttons";
import { TextInput } from "../components/Input";
import { useSocketEvents } from "../context/SocketEventsContext";

type Options = "unselected" | "join";

export const MainMenu = () => {
  const { createGameEvent, joinGameEvent } = useSocketEvents();

  const [code, setCode] = useState("");
  const [nickname, setNickname] = useState("");

  const [option, setOption] = useState<Options>("unselected");

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value.toUpperCase());
  };

  const handleNicknameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
  };

  const handleSelectOption = (option: Options) => {
    setOption(option);
  };

  const handleJoinGame = () => {
    joinGameEvent({ code, nickname });
  };

  const handleCreateGame = () => {
    createGameEvent();
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white bg-opacity-10 min-w-[420px] p-8 shadow-md rounded-lg flex flex-col items-center">
        {option === "unselected" && (
          <>
            <h1 className="text-white font-extrabold uppercase text-4xl">
              Welcome to Vampires
            </h1>
            <ButtonPurple onClick={() => handleSelectOption("join")}>
              Join Game
            </ButtonPurple>
            <ButtonOrange onClick={handleCreateGame}>Create Game</ButtonOrange>
          </>
        )}

        {option === "join" && (
          <>
            <ButtonBack onClick={() => handleSelectOption("unselected")} />
            <h1 className="text-white">Join a Game</h1>
            <TextInput
              label="code"
              onChange={handleCodeChange}
              value={code}
              placeholder="Enter code"
              maxLength={4}
            />

            <TextInput
              label="nickname"
              onChange={handleNicknameChange}
              value={nickname}
              placeholder="Enter nickname"
              maxLength={12}
            />
            <ButtonPurple onClick={handleJoinGame}>Go</ButtonPurple>
          </>
        )}
      </div>
    </div>
  );
};
