export enum Role {
  VAMPIRE = "VAMPIRE",
  SEER = "SEER",
  DOCTOR = "DOCTOR",
  VILLAGER = "VILLAGER",
}

export enum NightAction {
  VAMPIRE_HOVER = "VAMPIRE_HOVER",
  KILL = "KILL",
  SAVE = "SAVE",
  SEE = "SEE",
  KISS = "KISS",
}

export enum DayAction {
  VOTE = "VOTE",
}

export type Action = NightAction | DayAction | "SKIP";

export interface ClientPlayerSnapshot {
  nickname: string;
  isAlive: boolean;
  avatarId: string;
  acted: boolean;
  role: Role | null;
}

export interface ClientGameState {
  _roundTimeRemainingMS: number | null;
  code: string;
  host: string | null;
  isGameStarted: boolean;
  isGameOver: boolean;
  roundNumber: number;
  players: ClientPlayerSnapshot[];
  private: {
    amGameMaster: boolean;
    me?: ClientPlayerSnapshot;
    hovered?: { nickname: string; target: string }[];
  };
}

const roleActionMap = {
  VAMPIRE: [NightAction.KILL, NightAction.VAMPIRE_HOVER],
  SEER: [NightAction.SEE],
  DOCTOR: [NightAction.SAVE],
  VILLAGER: [NightAction.KISS],
};

export enum ServerEvents {
  START_CLOCK = "START_CLOCK",
  NOTIFY_SEER = "NOTIFY_SEER",
  UPDATE_GAME = "UPDATE_GAME",
  NARRATION_EVENT = "NARRATION_EVENT",
  END_NARRATION_EVENT = "END_NARRATION_EVENT",
}

export enum PlayerEvents {
  CREATE_GAME = "CREATE_GAME",
  JOIN_GAME = "JOIN_GAME",
  START_GAME = "START_GAME",
  RESTART_GAME = "RESTART_GAME",
  PERFORM_ACTION = "PERFORM_ACTION",
  SELECT_AVATAR = "SELECT_AVATAR",
  GAME_MASTER_READY = "GAME_MASTER_READY",
}

export enum SocketEvents {
  CONNECTION = "connection",
  DISCONNECT = "disconnect",
}

export enum NarrationEvent {
  START_GAME = "START_GAME",
  ROUND_OVER = "ROUND_OVER",
  GAME_OVER = "GAME_OVER",
}

export type RoundSummary = {
  killed?: string;
  saved?: string;
  voted?: string;
};
