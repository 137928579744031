import { useEffect } from "react";
import { TypingAnimation } from "../../components/TypingAnimation";
import { useGameContext } from "../../context/GameContext";
import { useSocketEvents } from "../../context/SocketEventsContext";
import { NarrationEvent } from "../../types/serverTypes";

function StartGameScene() {
  const { endNarration } = useSocketEvents();

  useEffect(() => {
    const timer = setTimeout(() => {
      endNarration();
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return <div>Starting Game Animation</div>;
}

function RoundOverScene() {
  const { endNarration } = useSocketEvents();
  const { animation } = useGameContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      endNarration();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  let text;
  if (animation?.data.killed) {
    text = `Tragically, ${animation.data.killed} was killed.`;
  } else if (animation?.data.saved) {
    text = `Miraculously, ${animation.data.saved} was saved.`;
  } else if (animation?.data.voted) {
    text = `${animation.data.voted} was voted out.`;
  } else {
    text = "Nothing happened....";
  }

  return (
    <div className="text-center">
      <TypingAnimation text={text} />{" "}
    </div>
  );
}

function GameOverScene() {
  const { endNarration } = useSocketEvents();
  const { animation } = useGameContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      endNarration();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  let text;

  if (animation.data.winners === "Vampires") {
    text = "The Vampires Win!";
  } else {
    text = "The Villagers Win!";
  }

  return (
    <div className="text-center">
      <h1>Game Over!</h1>
      <TypingAnimation text={text} />
    </div>
  );
}

export function Animation() {
  const { gameState, animation } = useGameContext();

  const { code, isPlaying } = animation;

  return (
    <div className="min-h-screen bg-slate-500 flex items-center justify-center">
      <div className=" min-w-[420px] p-8 rounded-lg flex flex-col items-center">
        {code === NarrationEvent.START_GAME && <StartGameScene />}
        {code === NarrationEvent.ROUND_OVER && <RoundOverScene />}
        {code === NarrationEvent.GAME_OVER && <GameOverScene />}
      </div>
    </div>
  );
}
