import styled from "styled-components";
import { useGameContext } from "../../context/GameContext";
import { ClientGameState, Role } from "../../types/serverTypes";

const LeftHalf = styled.div<{ $imgsrc: string }>`
  width: 50%;
  height: 100vh;
  background-image: url("${(props) => props.$imgsrc}");
  background-size: cover;
  background-position: center;
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const RightHalf = styled.div`
  width: 50%;
  height: 100vh;
  background-image: url("/cemetary.jpg");
  background-size: cover;
  background-position: center;
  position: fixed;
  right: 0;
  top: 0;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const Background = styled.div`
  z-index: -2;
  position: absolute;
  background: #000;
`;

const PlayerContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px 40px;
  border-radius: 8px;

  li {
    list-style: none;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
`;

export const GameOver = () => {
  const { gameState } = useGameContext();

  const data = computeGameOver(gameState);

  const vampires = gameState.players.filter(
    (player) => player.role === Role.VAMPIRE
  );
  const villagers = gameState.players.filter(
    (player) => player.role !== Role.VAMPIRE
  );

  const villagersWin = data.winners === "Villagers";

  const vampireAvatars = vampires.map((player, index) => (
    <div key={index} className="flex flex-col items-center w-auto">
      <img
        src={`/sprites/sprite-${player.avatarId}.png`}
        className="h-16 max-h-60 mb-2"
      />
      <li key={index} className="px-2 text-lg">
        {player.nickname}
      </li>
    </div>
  ));

  const villagerAvatars = villagers.map((player, index) => (
    <div key={index} className="flex flex-col items-center">
      <img
        src={`/sprites/sprite-${player.avatarId}.png`}
        className="h-16 max-h-60 mb-2"
      />
      <li key={index} className="px-2 text-lg">
        {player.nickname}
      </li>
    </div>
  ));

  const winnerAvatars =
    data.winners === "Vampires" ? vampireAvatars : villagerAvatars;
  const loserAvatars =
    data.winners === "Vampires" ? villagerAvatars : vampireAvatars;

  return (
    <>
      <Background>
        <LeftHalf
          $imgsrc={
            villagersWin ? "/gameover-villagers.png" : "/gameover-vampires.webp"
          }
        >
          <div>
            <h1 className="text-white font-extrabold uppercase text-4xl text-center">
              {data?.winners} Win!
            </h1>
            <PlayerContainer>{winnerAvatars}</PlayerContainer>
          </div>
        </LeftHalf>
        <RightHalf>
          <div>
            <h1 className="text-white font-extrabold uppercase text-4xl text-center">
              Stinky {data?.winners === "Vampires" ? "Villagers" : "Vampires"}{" "}
              Lose!
            </h1>
            <PlayerContainer>{loserAvatars}</PlayerContainer>
          </div>
        </RightHalf>
      </Background>
    </>
  );
};

function computeGameOver(game: ClientGameState) {
  let aliveVampires = 0;
  let aliveVillagers = 0;

  for (const player of game.players) {
    if (player.isAlive) {
      if (player.role === Role.VAMPIRE) {
        aliveVampires++;
      } else if (
        player.role === Role.VILLAGER ||
        player.role === Role.SEER ||
        player.role === Role.DOCTOR
      ) {
        aliveVillagers++;
      }
    }
  }

  if (aliveVampires === 0) {
    return { winners: "Villagers" } as const;
  }

  if (aliveVampires >= aliveVillagers) {
    return { winners: "Vampires" } as const;
  }
  return {};
}
