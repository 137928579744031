import { useGameContext } from "../../context/GameContext";

export const WaitingRoom = () => {
  const { gameState } = useGameContext();

  return (
    <div className="min-h-screen flex items-center flex-col justify-center">
      <div className="bg-white bg-opacity-10 w-[80%] md:w-[40%] lg:w-[420px] p-8 shadow-md rounded-lg flex flex-col items-center">
        <h1 className="text-white text-center font-extrabold uppercase text-2xl">
          Vampire village
        </h1>
        <span className="text-3xl text-center">
          Your code:
          <p className="text-center text-6xl font-bold font-title">
            {gameState.code}
          </p>
        </span>
        <ul className="flex space-x-2">
          {gameState.players.map((player, index) => (
            <div key={index} className="flex flex-col items-center">
              <img
                src={`/sprites/sprite-${player.avatarId}.png`}
                className="h-16 max-h-60 mb-2"
              />
              <li key={index} className="px-2 text-lg">
                {player.nickname}
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};
