import { useEffect, useState } from "react";

interface TypingAnimationProps {
  text: string;
  typingSpeed?: number; // Speed in milliseconds
}

export const TypingAnimation = ({
  text,
  typingSpeed = 70,
}: TypingAnimationProps) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const timer = setInterval(() => {
      setDisplayedText((prev) => text.substring(0, currentIndex));
      currentIndex++;
      if (currentIndex === text.length) clearInterval(timer);
    }, typingSpeed);

    return () => clearInterval(timer);
  }, [text, typingSpeed]);

  return (
    <div className="typing-animation">
      {displayedText}
      <span className="typing-cursor"></span>
    </div>
  );
};
