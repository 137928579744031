import { useGameContext } from "../../context/GameContext";
import { ButtonOrange } from "../../components/Buttons";
import { useSocketEvents } from "../../context/SocketEventsContext";

export const GameOver = () => {
  const { restartGameEvent } = useSocketEvents();
  const { gameState } = useGameContext();

  const handleReturnToLobby = () => {
    console.log("Game State: ");
    const code = gameState.code;
    restartGameEvent({ code });
  };

  return (
    <div className="min-h-screen flex items-center flex-col justify-center">
      <div className="bg-white bg-opacity-10 min-w-[420px] p-8 shadow-md rounded-lg flex flex-col items-center">
        <h1 className="text-white font-extrabold uppercase text-2xl">
          Game Over
        </h1>
        <ButtonOrange onClick={handleReturnToLobby}>
          Return to Lobby
        </ButtonOrange>
      </div>
    </div>
  );
};
