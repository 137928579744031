import "./App.css";
import { useGameContext } from "./context/GameContext";
import { Animation as GameMasterAnimation } from "./pages/GameMaster/Animation";
import { Game as GameMasterGame } from "./pages/GameMaster/Game";
import { GameOver as GameMasterGameOver } from "./pages/GameMaster/GameOver";
import { WaitingRoom as GameMasterWaitingRoom } from "./pages/GameMaster/WaitingRoom";
import { MainMenu } from "./pages/MainMenu";
import { Game } from "./pages/Player/Game";
import { GameOver } from "./pages/Player/GameOver";
import { WaitingRoom } from "./pages/Player/WaitingRoom";

function Router() {
  const { gameExists, gameState, animation } = useGameContext();

  const renderGameMaster = () => {
    if (animation.isPlaying) return <GameMasterAnimation />;
    if (gameState.isGameOver) return <GameMasterGameOver />;
    if (!gameState.isGameStarted) return <GameMasterWaitingRoom />;
    return <GameMasterGame />;
  };

  const renderPlayer = () => {
    if (animation.isPlaying) return <GameMasterAnimation />;
    if (gameState.isGameOver) return <GameOver />;
    if (!gameState.isGameStarted) return <WaitingRoom />;
    return <Game />;
  };

  if (!gameExists) {
    return <MainMenu />;
  } else {
    return gameState.private.amGameMaster ? renderGameMaster() : renderPlayer();
  }
}

export default Router;
