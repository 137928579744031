import React, { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import {
  Action,
  ClientGameState,
  NarrationEvent,
  PlayerEvents,
  ServerEvents,
} from "../types/serverTypes";
import { useGameContext } from "./GameContext";

function openNewSocketConnection() {
  return io(process.env.REACT_APP_BACKEND_URL as string);
}

interface JoinGameEvent {
  nickname: string;
  code: string;
}

interface StartGameEvent {
  vampires: number;
  seers: number;
  doctors: number;
}

interface RestartGameEvent {
  code: string;
}

interface SelectAvatarEvent {
  avatarId: string;
}

interface PerformActionEvent {
  code: string;
  action: Action;
  target: string;
}

interface SocketEventsContext {
  openSocketConnection: () => void;
  createGameEvent: () => void;
  joinGameEvent: (payload: JoinGameEvent) => void;
  startGameEvent: (payload: StartGameEvent) => void;
  restartGameEvent: (payload: RestartGameEvent) => void;
  selectAvatarEvent: (payload: SelectAvatarEvent) => void;
  performActionEvent: (payload: PerformActionEvent) => void;
  endNarration: () => void;
}

const SocketEventsContext = createContext<SocketEventsContext>(
  {} as SocketEventsContext
);

const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const { gameState, updateGame, StartClock, updateAnimationPlaying } =
    useGameContext();

  const [socket, setSocket] = useState<Socket>();

  const openSocketConnection = (cb?: (socket: Socket) => void) => {
    const newSocket = openNewSocketConnection();
    setSocket(newSocket);
    cb && cb(newSocket);
  };

  const createGameEvent = () => {
    if (!socket) {
      openSocketConnection((newSocket: Socket) => {
        newSocket.emit(PlayerEvents.CREATE_GAME);
      });
    } else {
      socket.emit(PlayerEvents.CREATE_GAME);
    }
  };

  const joinGameEvent = (payload: JoinGameEvent) => {
    if (!socket) {
      openSocketConnection((newSocket: Socket) =>
        newSocket.emit(PlayerEvents.JOIN_GAME, payload)
      );
    } else {
      socket.emit(PlayerEvents.JOIN_GAME, payload);
    }
  };

  const startGameEvent = (payload: StartGameEvent) => {
    if (!socket) return;
    socket.emit(PlayerEvents.START_GAME, payload);
  };

  const restartGameEvent = (payload: RestartGameEvent) => {
    if (!socket) return;
    socket.emit(PlayerEvents.RESTART_GAME, payload);
  };

  const selectAvatarEvent = (payload: SelectAvatarEvent) => {
    if (!socket) return;
    socket.emit(PlayerEvents.SELECT_AVATAR, payload);
  };

  const performActionEvent = (payload: PerformActionEvent) => {
    if (!socket) return;
    socket.emit(PlayerEvents.PERFORM_ACTION, payload);
  };

  const endNarration = () => {
    if (!socket) return;
    socket.emit(PlayerEvents.GAME_MASTER_READY);
  };

  useEffect(() => {
    if (!socket) return;

    socket.on(ServerEvents.UPDATE_GAME, (newGameState: ClientGameState) => {
      updateGame(newGameState);
    });

    socket.on(
      ServerEvents.START_CLOCK,
      ({ duration }: { duration: number }) => {
        console.log("here");
        StartClock(duration);
      }
    );

    socket.on("handleError", (errorMsg: string) => {
      // TO IMPLEMENT
      console.log("here", errorMsg);
      alert(errorMsg);
    });

    socket.on(
      ServerEvents.NOTIFY_SEER,
      ({ target, role }: { target: string; role: string }) => {
        alert(`${target} is a ${role}`);
      }
    );

    socket.on(
      ServerEvents.NARRATION_EVENT,
      (
        { code, data }: { code: NarrationEvent; data: any } // Maybe code can be used to set which animation plays
      ) => {
        updateAnimationPlaying({ isPlaying: true, code, data });
      }
    );

    socket.on(ServerEvents.END_NARRATION_EVENT, () => {
      updateAnimationPlaying({ isPlaying: false, code: null, data: undefined });
    });

    return () => {
      console.log("Disconnecting socket");
      socket.disconnect();
    };
  }, [socket]);

  //
  const context = {
    openSocketConnection,
    createGameEvent,
    joinGameEvent,
    startGameEvent,
    restartGameEvent,
    selectAvatarEvent,
    performActionEvent,
    endNarration,
  };

  return (
    <SocketEventsContext.Provider value={context}>
      {children}
    </SocketEventsContext.Provider>
  );
};

const useSocketEvents = () => {
  const socket = useContext(SocketEventsContext);
  if (!socket) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return socket;
};

export { SocketProvider, useSocketEvents };
