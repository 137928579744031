import { useGameContext } from "../../context/GameContext";

export const Game = () => {
  const { gameState } = useGameContext();
  const isNight = gameState.roundNumber % 2 === 1;

  return (
    <>
      <div className="min-h-screen flex items-center justify-center flex-col">
        {/* <div className="bg-white bg-opacity-10 min-w-[420px] p-8 shadow-md rounded-lg flex flex-col items-center"> */}
        <h1 className="text-white font-extrabold uppercase text-2xl">
          Round {Math.ceil(gameState.roundNumber / 2)}:{" "}
          {isNight ? "Night" : "Day"}
        </h1>
        {/* </div> */}
        <ul className="flex space-x-2 mt-8">
          {gameState.players.map((player, index) => (
            <div key={index} className="relative flex flex-col items-center">
              <img
                src={`/sprites/sprite-${player.avatarId}.png`}
                className="h-16 max-h-60 mb-2 object-cover "
              />
              {!player.isAlive && (
                <div className="absolute bottom-12 left-0 right-0 bottom-0 flex items-center justify-center">
                  <div className="text-5xl font-bold text-red-500">X</div>
                </div>
              )}
              <li key={index} className="px-2 text-lg">
                {player.nickname}
              </li>
              <Checkmark display={player.acted} />
              {/* <ImageWithStatus src={`/sprite-${index}.png`} isAlive={false} /> */}
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};

const Checkmark = ({ display }: { display: boolean }) => {
  if (!display) {
    return null;
  }

  return (
    <div className="text-green-500 text-4xl">
      <svg
        className="w-6 h-6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M5 13l4 4L19 7"></path>
      </svg>
    </div>
  );
};
