import React, { createContext, useContext, useState } from "react";
import { ClientGameState, NarrationEvent } from "../types/serverTypes";
import { useGameClock } from "./GameClockContext";

export interface GlobalTimer {
  msg: string;
  timer: any;
}

interface IGameContext {
  animation: Animation;
  gameExists: boolean;
  gameState: ClientGameState;
  updateGame: (gameObject: ClientGameState) => void;
  StartClock: (roundLength: number) => void;
  StopClock: () => void;
  updateAnimationPlaying: (animation: Animation) => void;
  getGameState: () => ClientGameState;
}

interface Animation {
  isPlaying: boolean;
  code: NarrationEvent | null;
  data: any;
}

/**
 * Architecture --
 *
 * The GameContext/GameProvider should never emit events directly.
 * If you ever get the urge to move this inside the SocketEventsProvider
 * ->>>>>>>> DO NOT DO IT!!! <<<<<<<<-
 *
 *  DATA FLOW:
 *                      Server
 *                   ↙          ↖
 *   SocketEventListeners  SocketEventMethods
 *               ↙                  ↖
 *     GameProviderMethods     Components
 *                ↘                ↗
 *                 ClientGameState
 *
 * Create functions here and then dispatch them in SocketEventsContext
 * as a result of receiving socket events from the Server.
 */
const GameContext = createContext({} as IGameContext);

const GameProvider = ({ children }: { children: React.ReactNode }) => {
  const { startCountdown, setCountdownActive } = useGameClock();

  const [animation, setAnimation] = useState<Animation>({
    isPlaying: false,
    code: null,
    data: undefined,
  });

  const [gameState, setGameState] = useState<ClientGameState>(
    {} as ClientGameState
  );

  // NEVER CALL THIS DIRECTLY
  const updateGame = (newGameState: ClientGameState) => {
    console.log("Updating game state", newGameState);
    setGameState(newGameState);
  };

  const StartClock = (duration: number) => {
    startCountdown(duration, () => {});
  };

  const StopClock = () => {
    setCountdownActive(false);
  };

  const updateAnimationPlaying = (animation: Animation) => {
    setAnimation(animation);
  };

  const getGameState = () => {
    return gameState;
  };

  const gameExists = Object.keys(gameState).length > 0;

  const context = {
    animation,
    updateAnimationPlaying,
    gameExists,
    gameState,
    updateGame,
    StartClock,
    StopClock,
    getGameState,
  };

  return (
    <GameContext.Provider value={context}>{children}</GameContext.Provider>
  );
};

const useGameContext = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error("useGameContext must be used within a GameProvider");
  }
  return context;
};

export { GameProvider, useGameContext };
