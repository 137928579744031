import { useState } from "react";
import styled from "styled-components";
import { ButtonPurple } from "../../components/Buttons";
import { RoleCountSelector } from "../../components/RoleSelector";
import SelectSprite from "../../components/SelectSprite";
import { useGameContext } from "../../context/GameContext";
import { useSocketEvents } from "../../context/SocketEventsContext";
import { H4 } from "../../styles";

export const WaitingRoom = () => {
  const { gameState } = useGameContext();
  const { startGameEvent, selectAvatarEvent } = useSocketEvents();

  const me = gameState.private.me;

  const handleStartGame = () => {
    startGameEvent(selectValues);
  };

  const handleSelectAvatar = (avatarId: string) => {
    selectAvatarEvent({ avatarId });
  };

  const [selectValues, setSelectValues] = useState({
    vampires: 1,
    seers: 1,
    doctors: 1,
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setSelectValues((prevValues) => ({
      ...prevValues,
      [name]: parseInt(value),
    }));
  };

  const amHost = gameState.host === me?.nickname;

  return (
    <Page>
      <Container className="bg-white bg-opacity-10 shadow-md rounded-lg">
        <div
          className={`w-full grid ${
            amHost ? "lg:grid-cols-2" : "lg:grid-cols-1"
          } gap-4 `}
        >
          <div className="w-full h-max ">
            <SelectSprite onSelectSprite={handleSelectAvatar} />
          </div>
          {amHost && (
            <div className="h-max mx-auto p-8 rounded-lg">
              <H4>Game Settings</H4>
              <RoleCountSelector
                values={selectValues}
                handleChange={handleSelectChange}
              />
              {selectValues.vampires +
                selectValues.doctors +
                selectValues.seers >
                gameState.players.length && (
                <p className="mt-4 text-xs text-rose-600">
                  Error: Number of roles must be equal or less than number of
                  players
                </p>
              )}
            </div>
          )}
        </div>
        <div className="h-max grid justify-center mt-4">
          <div className="h-max text-center  py-8 px-16 ounded-lg ">
            <h1 className="text-white text-center font-extrabold uppercase text-2xl">
              Vampire village
            </h1>
            <ul className="grid  grid-flow-col auto-cols-max pb-6 justify-center items-center text-center">
              {gameState.players.map((player, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img
                    src={`/sprites/sprite-${player.avatarId}.png`}
                    className="h-16 max-h-60 mb-2"
                  />
                  <li key={index} className="px-2 text-lg">
                    {player.nickname}
                  </li>
                </div>
              ))}
            </ul>

            {gameState.private.me?.nickname === gameState.host && (
              <ButtonPurple onClick={handleStartGame}>Start Game</ButtonPurple>
            )}
          </div>
        </div>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  min-height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
`;
