import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface GameClockContextProps {
  timeRemaining: number;
  isActive: boolean;
  startCountdown: (initialTime: number, onCountdownEnd?: () => void) => void;
  restartCountdown: () => void;
  setCountdownActive: (active: boolean) => void;
  setCountdownTime: (time: number) => void;
}

const GameClockContext = createContext<GameClockContextProps | undefined>(
  undefined
);

export const GameClockProvider = ({ children }: any) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [onCountdownEndCallback, setOnCountdownEndCallback] =
    useState<() => void | undefined>();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isActive && timeRemaining > 0) {
      intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => Math.max(0, prevTime - 1000));
      }, 1000);
    } else if (isActive && timeRemaining === 0) {
      setIsActive(false);
      onCountdownEndCallback && onCountdownEndCallback();
    }

    return () => clearInterval(intervalId);
  }, [isActive, timeRemaining]);

  const startCountdown = useCallback(
    (initialTime: number, cb?: () => void) => {
      setTimeRemaining(initialTime);
      setIsActive(true);
      // https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      setOnCountdownEndCallback(() => cb);
    },
    [setTimeRemaining, setIsActive]
  );

  const restartCountdown = () => {
    setIsActive(true);
  };

  const setCountdownActive = (active: boolean) => {
    setIsActive(active);
  };

  const setCountdownTime = (time: number) => {
    setTimeRemaining(time);
  };

  const contextValue: GameClockContextProps = {
    timeRemaining,
    isActive,
    startCountdown,
    restartCountdown,
    setCountdownActive,
    setCountdownTime,
  };

  return (
    <GameClockContext.Provider value={contextValue}>
      {children}
    </GameClockContext.Provider>
  );
};

export const useGameClock = () => {
  const context = useContext(GameClockContext);
  if (!context) {
    throw new Error("useCountdown must be used within a CountdownProvider");
  }

  return context;
};
