import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-right: 8px;
`;

const Select = styled.select`
  width: 80px;
  padding: 4px 8px;
  font-size: 14px;
  color: #333;
`;

type Props = {
  values: any;
  handleChange: any;
};

export const RoleCountSelector = ({ values, handleChange }: Props) => {
  return (
    <Wrapper>
      {["vampires", "seers", "doctors"].map((selectName) => (
        <div key={selectName}>
          <Label htmlFor={selectName}>
            {selectName.charAt(0).toUpperCase() + selectName.slice(1)}
          </Label>
          <Select
            name={selectName}
            id={selectName}
            value={values[selectName as keyof typeof values]}
            onChange={handleChange}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </Select>
        </div>
      ))}
    </Wrapper>
  );
};
