import { useGameClock } from "../context/GameClockContext";

export const GlobalTimerComponent = () => {
  const { timeRemaining, isActive } = useGameClock();

  return (
    <div className="fixed bottom-10 left-20 p-4 z-50">
      {!isActive && <p>Clock Inactive</p>}
      {isActive && <p>{timeRemaining / 1000} seconds</p>}
    </div>
  );
};
