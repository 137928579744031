import React from "react";
const hoverSound = new Audio(process.env.PUBLIC_URL + "/button-hover.mp3");
const clickSound = new Audio(process.env.PUBLIC_URL + "/button-click.mp3");

interface ButtonProps {
  children?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
}

const handleButtonClick =
  (onClick: React.MouseEventHandler<HTMLButtonElement>) =>
  (e: React.MouseEvent<HTMLButtonElement>) => {
    clickSound.play().catch(function (error) {
      console.log("Error playing click sound:", error);
    });
    onClick(e);
  };

const handleMouseEnter = () => {
  hoverSound.play().catch(function (error) {
    console.log("Chrome cannot play sound without user interaction first");
  });
};

export const ButtonPurple = ({ onClick, children }: ButtonProps) => {
  return (
    <button
      onClick={handleButtonClick(onClick)}
      onMouseEnter={handleMouseEnter}
      className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
    >
      <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
        {children}
      </span>
    </button>
  );
};

export const ButtonOrange = ({ onClick, children }: ButtonProps) => {
  return (
    <button
      onClick={handleButtonClick(onClick)}
      onMouseEnter={handleMouseEnter}
      className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800"
    >
      <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
        {children}
      </span>
    </button>
  );
};

export const ButtonBack = ({ onClick }: ButtonProps) => {
  return (
    <button
      onClick={handleButtonClick(onClick)}
      onMouseEnter={handleMouseEnter}
    >
      <img src="/back.svg" alt="Back" width="24" height="24" />
    </button>
  );
};
